import React from "react";
import Avatar from "react-avatar";
import classes from "./UserProfileInformation.module.css";
import profilePicture from "../../../../assets/images/profilePicture.png";
import * as Icon from "iconsax-react";
import { useState, useEffect } from "react";
import API from "../../../../../services/api";

const userFormInterface = {
  email: "",
  fullName: "",
  phoneNumber: "",
  username: "",
  dialCode: "",
};

const UserProfileInformation = (props) => {
  let [formField, setFormField] = useState(userFormInterface);
  let [submitting, setSubmitting] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setFormField(props.formData);
  }, []);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormField({ ...formField, [name]: value });
  }

  const selectFile = () => {
    document.getElementById("fileid").click();
  };

  const HandlePictureChange = () => {
    const file = document.getElementById("fileid").files[0];
    var data = new FormData();
    data.append("photo", file);
    API.post(`/dashboard/user/update/profile/picture`, data).then((res) => {
      // console.log(res);
      props.updatedUser(res.data.results.user);
      props.showMessage({
        enabled: true,
        message: "Profile picture has been Updated",
      });
    });
  };

  function onSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage("");
    console.log(formField);
    if (
      formField.email === "" ||
      formField.fullName === "" ||
      formField.phoneNumber === "" ||
      formField.username === "" ||
      formField.dialCode === ""
    ) {
      setErrorMessage("Fill all fields");
      setSubmitting(false);
      return;
    }
    API.post(`/dashboard/user/update/profile`, {
      ...formField,
    })
      .finally(() => {
        setSubmitting(false);
      })
      .then((response) => {
        if (response.data.error) {
          setErrorMessage(response.data.message);
          return;
        }
        props.updatedUser(response.data.results.user, true);
      })
      .catch((error) => {
        if (error.response.data.error) {
          setErrorMessage(error.response.data.message);
          return;
        }
        setErrorMessage(error.message);
      });
  }

  return (
    <div className={classes["container"]}>
      <p className={classes["title"]}>User Settings</p>
      <div className={classes["profile-picture-action"]}>
        {props.profilePicture ? (
          <img
            className={classes["profile-picture"]}
            src={`data:image/jpeg;base64,${props.profilePicture}`}
            alt="profilePicture"
          />
        ) : (
          <Avatar
            color={props.ppColor}
            name={`${
              props.formData.fullName
                ? props.formData.fullName
                : props.formData.phoneNumber
            }`}
            round={true}
          />
        )}
        <input
          id="fileid"
          type="file"
          hidden
          accept="image/jpeg,image/png,image/svg"
          onChange={HandlePictureChange}
        />
        <button
          className={classes["change-profile-picture-button"]}
          onClick={selectFile}
        >
          Upload Image
        </button>
      </div>
      <div className={classes["border-line"]}></div>
      <form onSubmit={onSubmit} autoComplete="off">
        <div className={classes["input-field-container"]}>
          <Icon.User />
          <input
            className={classes["input-field"]}
            required={true}
            placeholder="Full Name"
            name={"fullName"}
            onChange={handleInputChange}
            value={formField.fullName}
          />
        </div>
        <div className={classes["space-around"]}></div>
        <div className={classes["input-field-container"]}>
          <Icon.User />
          <input
            className={classes["input-field"]}
            required={true}
            placeholder="Username"
            name={"username"}
            onChange={handleInputChange}
            value={formField.username}
          />
        </div>
        <div className={classes["space-around"]}></div>
        <div className={classes["input-field-container"]}>
          <Icon.Sms />
          <input
            className={classes["input-field"]}
            placeholder="Email Address"
            required={true}
            name={"email"}
            onChange={handleInputChange}
            value={formField.email}
          />
        </div>
        <div className={classes["space-around"]}></div>
        <div className={classes["input-field-container"]}>
          <Icon.Call />
          <input
            className={classes["input-field-dial-code"]}
            placeholder="Dial code"
            name={"dial_code"}
            required={true}
            onChange={handleInputChange}
            disabled
            value={`+${formField.dialCode}`}
          />
          <input
            className={classes["input-field-phone"]}
            placeholder="Phone number"
            name={"phoneNumber"}
            required={true}
            onChange={handleInputChange}
            value={formField.phoneNumber}
          />
        </div>
        {errorMessage !== "" && (
          <p className={classes["errormessage"]}>{errorMessage}</p>
        )}
        <div className={classes["button-container"]}>
          <button
            disabled={submitting}
            type={"submit"}
            className={classes["update-profile"]}
          >
            {submitting ? (
              <div className={classes["loadingbutton"]}>
                <div className="sk-circle">
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                  <div className="sk-circle-dot"></div>
                </div>
              </div>
            ) : (
              "Update Profile"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfileInformation;

import React from "react";
import SuccessImage from "../../assets/images/successful.svg";
import classes from "./AuthConfirmations.module.css";
import MobilePix from "../../assets/images/successMobilePic.png";

const AuthConfirmations = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.mobilePixContainer}>
        <img src={MobilePix} alt="Img" className={classes.mobilePix} />
      </div>
      <img
        className={classes.confirmimage}
        src={SuccessImage}
        alt="successful"
      />
      <h4 className={classes.greetingheading}>{props.heading}</h4>
      <p className={classes.greetingsubtitle}>{props.message}</p>
    </div>
  );
};

export default AuthConfirmations;

import { Navigate, Outlet } from "react-router-dom";
import Header from "../Components/Header/Header";
import AuthHome from "../Components/Pages/AuthHome/AuthHome";
import Home from "../Components/Pages/Dashboard/Home/Home";
import Trips from "../Components/Pages/Dashboard/Trip/Trip";
import Earnings from "../Components/Pages/Dashboard/Earnings/Earnings";
import Login from "../Components/Pages/auth/login/Login";
import ForgetPassword from "../Components/Pages/auth/forget-password/forget-password";
import Register from "../Components/Pages/auth/register/Register";
import Logout from "../Components/Pages/Dashboard/Logout/Logout";
import UserProfile from "../Components/Pages/Dashboard/UserProfile/UserProfile";
import CSupport from "../Components/Pages/Dashboard/CSupport/CSupport";

const routes = (isLoggedIn) => [
  {
    path: "/dashboard",
    element: isLoggedIn ? <Header /> : <Navigate to="/" />,
    children: [
      { path: "/dashboard", element: <Home /> },
      { path: "/dashboard/trips", element: <Trips /> },
      { path: "/dashboard/earnings", element: <Earnings /> },
      { path: "/dashboard/user-profile", element: <UserProfile /> },
      { path: "/dashboard/message" },
      { path: "/dashboard/c-support", element: <CSupport /> },
      // { path: "/dashboard", element: <Navigate to="/dashboard/trips" /> },
      // { path: "/", element: <Navigate to="/dashboard" /> },
    ],
  },
  {
    path: "/logout",
    element: isLoggedIn ? <Logout /> : <Navigate to="/" />,
  },
  {
    path: "/",
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/dashboard" />,
    children: [
      { path: "/", element: <AuthHome /> },
      {
        path: "/auth",
        element: <Outlet />,
        children: [
          { path: "/auth/login", element: <Login /> },
          { path: "/auth/forget-password", element: <ForgetPassword /> },
          { path: "/auth/register", element: <Register /> },
        ],
      },
      //   { path: "/", element: <Navigate to="/login" /> },
    ],
  },
];

export default routes;

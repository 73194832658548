import React from "react";
import classes from "./AssignedTrips.module.css";
import { NavLink } from "react-router-dom";

const AssignedTrips = (props) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["top-section"]}>
        <div className={classes["top-section-label"]}>Assigned</div>
        <div className={classes["top-section-trip-id"]}>
          Trip ID - #{props.trip.trip_code}
        </div>
      </div>
      <div className={classes["middle-section"]}>
        <div className={classes["middle-section-trip-start"]}>
          <div className={classes["middle-section-trip-start-dot"]}></div>
          <div className={classes["middle-section-trip-start-location"]}>
            {props.trip.start_location}
          </div>
          <div className={classes["container-button"]}>
            <button className={classes["middle-section-accept-trip"]}>
              <NavLink className={classes["nav-link"]} to="/dashboard/trips">
                Accept Trip
              </NavLink>
            </button>
          </div>
        </div>
        <div className={classes["middle-section-trip-border"]}>
          <p className={classes["middle-section-trip-border-line"]}></p>
        </div>
        <div className={classes["middle-section-trip-start"]}>
          <div className={classes["middle-section-trip-start-dot"]}></div>
          <div className={classes["middle-section-trip-start-location"]}>
            {props.trip.destination_location}
          </div>
          <div className={classes["container-button"]}>
            <button className={classes["middle-section-decline-trip"]}>
              Decline Trip
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedTrips;

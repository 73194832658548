import React from "react";
import classes from "./SecurityCard.module.css";

const SecurityCard = (props) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["descriptions"]}>
        <p className={classes["heading"]}>{props.heading}</p>
        <p className={classes["sub-title"]}>{props.description}</p>
      </div>
      <div className={classes["action"]}>
        {/* <button className={classes["action-button"]}>Enable</button> */}
        <p>Coming soon...</p>
      </div>
    </div>
  );
};

export default SecurityCard;

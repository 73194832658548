import React, {Fragment, useEffect, useState} from "react";
import classes from "./RegisterForm.module.css";
import API from "../../../../../services/api";
import {Link} from "react-router-dom";
import MobilePix from "../../../../assets/images/Group Picture.png";

export const data = {
    username: "",
    phone_code: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
};

const RegisterForm = (props) => {
    let [loading, setLoading] = useState(true);
    let [submitting, setSubmitting] = useState(false);
    let [nationalities, setNationalities] = useState([]);
    let [formField, setFormField] = useState(data);
    let [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        API.get(`/config/nationalities`)
            .finally(() => {setLoading(false)})
            .then(res => {
                setNationalities(res.data.results.nationalities);
            })
    }, []);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setFormField({...formField, [name]: value});
    }

    function onSubmit(e) {
        e.preventDefault();
        setSubmitting(true);
        setErrorMessage('');
        if (formField.username === '' || formField.phone_code === '' || formField.phone_number === '' || formField.password === '' || formField.password_confirmation === '') {
            setErrorMessage('Fill all fields');
            setSubmitting(false);
            return;
        }
        API.post(`/auth/register/ef12ee52-2ffb-499f-b0a0-fed8443205ad`, {...formField})
            .finally(() => {setSubmitting(false)})
            .then(response => {
                if (response.data.error) {
                    setErrorMessage(response.data.message);
                    return;
                }
                props.successful();
            })
            .catch(error => {
                if (error.response.data.error) {
                    setErrorMessage(error.response.data.message);
                    return;
                }
                setErrorMessage(error.message)
            })
    }

    return (
      <div className={classes.container}>
        {loading ? (
          <div className={classes["preloader"]}>
            <div className="sk-circle">
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.mobilePixContainer}>
              <img src={MobilePix} alt="Img" className={classes.mobilePix} />
            </div>
            <h1 className={classes.heading}>Create An Account</h1>
            <form onSubmit={onSubmit} autoComplete="off">
              <input
                required={true}
                type="text"
                value={formField.username}
                name={"username"}
                className={classes.fullnamefield}
                onChange={handleInputChange}
                placeholder={"User Name"}
              />
              <div className={classes.phonesection}>
                <select
                  required={true}
                  value={formField.phone_code}
                  name="phone_code"
                  id="nationality"
                  onChange={handleInputChange}
                  className={classes.nationalityfield}
                >
                  <option value={""}>Dial Code</option>
                  {nationalities.length > 0 &&
                    nationalities.map((value) => {
                      return (
                        <option key={value.id} value={"+" + value.phone_code}>
                          {value.country_name} (+{value.phone_code})
                        </option>
                      );
                    })}
                </select>
                <input
                  required={true}
                  value={formField.phone_number}
                  type="tel"
                  name={"phone_number"}
                  className={classes.phonenumberfield}
                  onChange={handleInputChange}
                  placeholder={"Phone Number"}
                />
              </div>
              <input
                required={true}
                type="password"
                value={formField.password}
                name={"password"}
                className={classes.passwordfield}
                onChange={handleInputChange}
                placeholder={"Password"}
              />
              <input
                required={true}
                type="password"
                value={formField.password_confirmation}
                name={"password_confirmation"}
                className={classes.passwordconfirmationfield}
                onChange={handleInputChange}
                placeholder={"Confirm Password"}
              />
              {errorMessage !== "" && (
                <p className={classes.errormessage}>{errorMessage}</p>
              )}
              <button type="submit" className={classes.continue}>
                {submitting ? (
                  <div className={classes["loadingbutton"]}>
                    <div className="sk-circle">
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                      <div className="sk-circle-dot"></div>
                    </div>
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </form>
            <p className={classes.signup}>
              Have an account? <Link to="/auth/login">Log me in</Link>
            </p>
          </>
        )}
      </div>
    );
};

export default RegisterForm;

import * as type from "../actions/actionTypes";

export default function userReducer(state = [], action) {
  switch (action.type) {
    case type.USER_TOKEN:
      return [...state, { ...action.user }];
    default:
      return state;
  }
}

import React, { Fragment, useEffect, useState } from "react";
import classes from "./Trip.module.css";
import Assigned from "../../../UI/Assigned/Assigned";
import Ongoing from "../../../UI/Ongoing/Ongoing";
import Completed from "../../../UI/Completed/Completed";
import TripDetails from "../../../UI/Ongoing/TripDetails/TripDetails";
import Header from "../../../Header/Header";
import api from "../../../../services/api";
import { Helmet } from "react-helmet";
import Pagination from "../../../UI/Pagination/Pagination";
import * as Icon from "iconsax-react";

const distanceInfo = {
  assigned: 0,
  ongoing: 0,
  completed: 0,
};

const Trip = () => {
  const [currentView, setCurrentView] = useState("assigned");
  const [viewTrip, setViewTrip] = useState();
  const [apiData, setApiData] = useState();
  const [ongoingDataHandler, setOngingData] = useState([]);
  const [assignedDataHandler, setAssignedData] = useState([]);
  const [completedDataHandler, setCompletedData] = useState([]);
  let [paginateFullData, setPaginateFullData] = useState([]);
  let [dataToShow, setDataToShow] = useState([]);
  let [distanceValue, setDistanceValue] = useState(distanceInfo);
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsPerPage, setTripsPerPage] = useState(10);

  useEffect(() => {
    setLoading(true);
    getTrips();
  }, []);

  function getTrips() {
    api.get("/dashboard/trips/driver/assigned").then((rest) => {
      const apiDataHandler = rest.data.results.trips;
      setApiData(apiDataHandler);
      setLoading(false);
      sortAPI(apiDataHandler);
    });
  }

  /**
   *
   * @param {array} data
   */
  const sortAPI = (data) => {
    if (data.length > 0) {
      // Will Review this logic
      let assignedDistance = 0;
      let ongoingDistance = 0;
      let completedDistance = 0;

      let assignedTrip = [];
      let ongoingTrip = [];
      let completedTrip = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].state == null && data[i].status == "assigned") {
          assignedDistance = assignedDistance + parseFloat(data[i].distance);
          assignedTrip.push(data[i]);
        }
        if (data[i].state && data[i].status == "ongoing") {
          ongoingDistance = ongoingDistance + parseFloat(data[i].distance);
          ongoingTrip.push(data[i]);
        }
        if (data[i].state && data[i].status == "completed") {
          completedDistance = completedDistance + parseFloat(data[i].distance);
          completedTrip.push(data[i]);
        }

        // switch (data[i].status) {
        //   case "assigned":
        //     assignedDistance = assignedDistance + parseFloat(data[i].distance);
        //     assignedTrip.push(data[i]);
        //     break;
        //   case "ongoing":
        //     ongoingDistance = ongoingDistance + parseFloat(data[i].distance);
        //     ongoingTrip.push(data[i]);
        //     break;
        //   case "completed":
        //     completedDistance =
        //       completedDistance + parseFloat(data[i].distance);
        //     completedTrip.push(data[i]);
        //     break;
        //   default:
        //     return;
        // }
      }

      // const assignedData = data.filter(value => {
      //   return value.status === 'assigned';
      // })
      // const ongoingData = data.filter(value => {
      //   return value.status === 'ongoing';
      // });

      // const completedData = data.filter(value => {
      //   return value.status === 'completed';
      // })
      const newData = {
        assigned: assignedDistance.toFixed(2),
        ongoing: ongoingDistance.toFixed(2),
        completed: completedDistance.toFixed(2),
      };
      setAssignedData(assignedTrip);
      setOngingData(ongoingTrip);
      setCompletedData(completedTrip);
      setDistanceValue({ ...newData });
      setPaginateFullData(assignedTrip);
    }
  };

  /**
   *
   * @param {array} value
   */
  const viewTripHandler = (value) => {
    getTrips();
    setViewTrip(value);
  };

  const closeTripHandler = () => {
    setViewTrip(false);
  };

  function refreshTrips() {
    getTrips();
  }

  /**
   *
   * @param {string} view
   */
  function switchView(view) {
    switch (view) {
      case "assigned":
        setCurrentView("assigned");
        searchKeyWord !== '' ? onSearch({}, true) : setPaginateFullData(assignedDataHandler);
        break;
      case "ongoing":
        setCurrentView("ongoing");
        searchKeyWord !== '' ? onSearch({}, true) : setPaginateFullData(ongoingDataHandler);
        break;
      case "completed":
        setCurrentView("completed");
        searchKeyWord !== '' ? onSearch({}, true) : setPaginateFullData(completedDataHandler);
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param {array} data
   */
  function handlePaginationCallBack(data) {
    setDataToShow(data);
  }

  /**
   * 
   * @param {object} e 
   * @param {bool} switching 
   */
  function onSearch(e, switching = false) {
    let keyWord;
    if (switching) {
      keyWord = searchKeyWord
    } else {
      let { name, value } = e.target;
      keyWord = value;
      setSearchKeyWord(keyWord.toString())
    }
    let result = [];
    switch(currentView) {
      case "assigned":
        result = assignedDataHandler.filter((item) => {
          const hayStack = item.trip_code.toString().toLowerCase();
          return hayStack.indexOf(keyWord.toString().toLowerCase()) > -1;
        });
        break;
      case "ongoing":
        result = ongoingDataHandler.filter((item) => {
          const hayStack = item.trip_code.toString().toLowerCase();
          return hayStack.indexOf(keyWord.toString().toLowerCase()) > -1;
        });
        break;
      case "completed":
        result = completedDataHandler.filter((item) => {
          const hayStack = item.trip_code.toString().toLowerCase();
          return hayStack.indexOf(keyWord.toString().toLowerCase()) > -1;
        });
        break;
      default:
        break;
    }
    if (result.length < 1 ) {
      // setDataToShow([])
    }
    setPaginateFullData((result));
  }

  return (
    <>
      <Helmet>
        <title>iDriva - Trips</title>
      </Helmet>
      <div className={classes.background}>
        <div className={classes["heading-container"]}>
          <div className={classes["heading-container-left"]}>
            <div className={classes["trip-header"]}>Trips</div>
            <div className={classes["flex-container"]}>
              <div className={classes.details}>
                <p className={classes["trip-status"]}>{currentView} trips</p>
                <p className={classes["details-2"]}>
                  {
                    {
                      assigned: assignedDataHandler.length,
                      ongoing: ongoingDataHandler.length,
                      completed: completedDataHandler.length,
                    }[currentView]
                  }
                </p>
              </div>
              <div className={classes.details}>
                <p className={classes.distance}>Total Distance</p>
                <p className={classes["details-3"]}>
                  {
                    {
                      assigned: distanceValue.assigned,
                      ongoing: distanceValue.ongoing,
                      completed: distanceValue.completed,
                    }[currentView]
                  }{" "}
                  KM
                </p>
              </div>
            </div>
          </div>
          <div className={classes["heading-container-right"]}>
            <div className={classes["search-field"]}>
              <Icon.SearchNormal />
              <input
                type="text"
                className={classes["search"]}
                onKeyUp={onSearch}
                name="search trip"
                placeholder="Search for Trip Number"
              ></input>
            </div>
          </div>
        </div>
        <nav>
          <ul className={classes["trip-status-container"]}>
            <li className={classes["each-status"]}>
              <div
                className={
                  currentView == "assigned" ? classes["activeView"] : ""
                }
                onClick={() => switchView("assigned")}
              >
                <button className={classes["unsetbutton"]}>Assigned</button>
              </div>
            </li>
            <li className={classes["each-status"]}>
              <div
                className={
                  currentView == "ongoing" ? classes["activeView"] : ""
                }
                onClick={() => switchView("ongoing")}
              >
                <button className={classes["unsetbutton"]}>Ongoing</button>
              </div>
            </li>
            <li className={classes["each-status"]}>
              <div
                className={
                  currentView == "completed" ? classes["activeView"] : ""
                }
                onClick={() => switchView("completed")}
              >
                <button className={classes["unsetbutton"]}>Completed</button>
              </div>
            </li>
          </ul>
        </nav>
        <div className={classes["trip-list-container"]}>
          {
            {
              assigned:
                dataToShow.length > 0 ? (
                  dataToShow.map((value) => {
                    return (
                      <Assigned
                        key={value.id}
                        onAssigned={value}
                        onTripHandler={viewTripHandler}
                        refreshTripScreen={refreshTrips}
                      />
                    );
                  })
                ) : (
                  <div className={classes["API-Response"]}>
                    No assigned trip...
                  </div>
                ),
              ongoing:
                dataToShow.length > 0 ? (
                  dataToShow.map((value) => {
                    return (
                      <Ongoing
                        key={value.id}
                        onGoing={value}
                        onGoingHandler={viewTripHandler}
                      />
                    );
                  })
                ) : (
                  <div className={classes["API-Response"]}>
                    No ongoing trip...
                  </div>
                ),
              completed:
                dataToShow.length > 0 ? (
                  dataToShow.map((value) => {
                    return <Completed key={value.id} onCompleted={value} />;
                  })
                ) : (
                  <div className={classes["API-Response"]}>
                    No completed trip...
                  </div>
                ),
            }[currentView]
          }
        </div>
        <div className={classes["pagination-container"]}>
          <Pagination
            fullData={paginateFullData}
            paginatedData={handlePaginationCallBack}
            perPage={3}
          />
        </div>
        {viewTrip && <TripDetails onTripDetails={viewTrip} />}
      </div>
    </>
  );
};

export default Trip;

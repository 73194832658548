import React from "react";
import classes from "./MobileCard.module.css";
import * as Icon from "iconsax-react";

/**
 * 
 * @param {object} props
 * @param {string} props.heading
 * @param {string} props.subtitle
 * @returns 
 */
const MobileCard = (props) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["leading"]}>
        <div className={classes["leadingIconContainer"]}>
          <Icon.Add />
        </div>
      </div>
      <div className={classes["descriptionContainer"]}>
        <span className={classes["heading"]}>{props.heading}</span>
        <span className={classes["subheading"]}>{props.subtitle}</span>
      </div>
      <div className={classes["ending"]}>
        <Icon.ArrowRight2 />
      </div>
    </div>
  );
};

export default MobileCard;

import classes from './ForgetPasswordForm.module.css';
import React, { Fragment, useState } from "react";
import API from "../../../../../services/api";
import MobilePix from "../../../../assets/images/Group Picture.png";
import { Link } from "react-router-dom";

const data = {
    phone_number: "",
  };

const ForgetPasswordForm = (props) => {
    let [formField, setFormField] = useState(data);
    let [submitting, setSubmitting] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");
    let [parentErrorMessage, setParentErrorMessage] = useState(props.errorMessage);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setFormField({ ...formField, [name]: value });
    }

    function onSubmit(e) {
        e.preventDefault();
        setSubmitting(true);
        setErrorMessage("");
        if (formField.phone_number === "") {
          setErrorMessage("Fill all fields");
          setSubmitting(false);
          return;
        }
        API.get(`/auth/check/user/${formField.phone_number}`)
          .finally(() => {
            setSubmitting(false);
          })
          .then((response) => {
            if (response.data.error) {
              setErrorMessage(response.data.message);
              return;
            }
            const fullMobileNumber = `+${response.data.results.user.nationalities.phone_code}${response.data.results.user.phone_number}`;
            const token = response.data.results.user.reset_password_token;
            props.successful(fullMobileNumber, token);
            return;
          })
          .catch((error) => {
            if (error.response.data.error) {
              setErrorMessage(error.response.data.message);
              return;
            }
            setErrorMessage(error.message);
          });
    }

    return (
        <div className={classes.container}>
      <div className={classes.mobilePixContainer}>
        <img src={MobilePix} alt="Img" className={classes.mobilePix} />
      </div>
      <h1 className={classes.heading}>Recover Password</h1>
      <p className={classes.subheading}>Enter your phone number, to be able to reset your password</p>
      <form onSubmit={onSubmit} autoComplete="off">
        <input
          type="tel"
          name={"phone_number"}
          required={true}
          value={formField.phone_number}
          onChange={handleInputChange}
          className={classes.phonenumberfield}
          placeholder={"Phone Number"}
        />
        {errorMessage !== "" && (
          <p className={classes.errormessage}>{errorMessage}</p>
        )}
        {parentErrorMessage !== "" && (
          <p className={classes.errormessage}>{parentErrorMessage}</p>
        )}
        <button
          disabled={submitting}
          type={"submit"}
          className={classes.continue}
        >
          {submitting ? (
            <div className={classes["loadingbutton"]}>
              <div className="sk-circle">
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
              </div>
            </div>
          ) : (
            "Continue"
          )}
        </button>
        <p className={classes.login}>
          Return to? <Link to="/auth/login">Login</Link>
        </p>
      </form>
    </div>
    )
}

export default ForgetPasswordForm;
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classes from "./Login.module.css";
import LandingBackground from "../../../UI/Landing/LandingBackground";
import AuthConfirmations from "../../../UI/AuthConfirmations/AuthConfirmations";
import LoginForm from "./LoginForm/LoginForm";
import * as userActions from "../../../../redux/actions/userRegisteration";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import VerifyPhoneNumber from "../verify-phone-number/VerifyPhoneNumber";
import API from "../../../../services/api";

let credential = {
  token: "",
};

const Login = (props) => {
  let [success, setSuccess] = useState(false);
  let [currentview, setCurrentview] = useState('form');
  let [timer, setTimer] = useState(5);
  let [name, setName] = useState();
  let [mobileNumber, setMobileNumber] = useState('');
  let [intervalID, setIntervalID] = useState();
  let [userData, setUserData] = useState();
  let [sendErrorMessage, setSendErrorMessage] = useState('');

  useEffect(() => {
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  function onSuccess(data) {
    setName(data.user.username ? data.user.username : data.user.phone_number);
    setUserData((userData = data));
    if (data.user.verified && data.user.account_verified_at !== null) {
      loginUser();
    } else {
      API.get(`/config/nationality/${data.user.nationalities_id}`)
        .then((response) => {
          if (response.data.error) {
            setSendErrorMessage("Could not verify this account's nationality");
            return;
          }
          const fullMobileNumber = `+${response.data.results.nationality.phone_code}${data.user.phone_number}`;
          setMobileNumber(fullMobileNumber);
          setCurrentview('otp');
        })
        .catch((error) => {
          if (error.response.data.error) {
            setSendErrorMessage("Could not verify this account's nationality");
            return;
          }
          setSendErrorMessage("Could not verify this account's nationality");
        });
    }    
  }

  function activateuser() {
    const data = userData;
    API.put(`/auth/activate/${data.user.activation_token}`)
        .then((response) => {
          if (response.data.error) {
            setSendErrorMessage("Could not activate this account");
            return;
          }
          loginUser();
        })
        .catch((error) => {
          if (error.response.data.error) {
            setSendErrorMessage("Could not activate this account");
            return;
          }
          setSendErrorMessage("Could not activate this account");
        });
  }

  function loginUser() {
    const data = userData;
    setCurrentview('success');
    const token = data.token_type + " " + data.access_token;
    window.localStorage.setItem("token", token);
    credential.token = token;
    props.actions.userRegistration(credential);
    let interval = setInterval(() => {
      setTimer((timer = timer - 1));
    }, 1000);
    setIntervalID(interval);
    if (timer <= 0) {
      clearInterval(interval);
    }
    setTimeout(() => {
      window.location.href = "/dashboard";
    }, 5000);
  }

  return (
    <>
      <Helmet>
        <title>iDriva - Login</title>
      </Helmet>
      <div className={classes.body}>
        <LandingBackground />
        {
          {
            form: <LoginForm successful={onSuccess} errorMessage={sendErrorMessage} />,
            otp: <VerifyPhoneNumber mobileNumber={mobileNumber} returnto="/auth/login" successful={activateuser} errorMessage={sendErrorMessage} />,
            success: <AuthConfirmations
            heading={`Welcome Back! ${name}`}
            message={`You will be automatically redirected to your dashboard in ${timer}`}
          />
          }[currentview]
        }
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

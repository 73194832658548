import classes from "./VerifyPhoneNumber.module.css";
import React, { useState, useRef, useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import * as Icon from "iconsax-react";

const listOffields = {
  1: "first",
  2: "second",
  3: "third",
  4: "forth",
  5: "fifth",
  6: "sixth",
};

const fire = {
  recaptchaVerifier: "",
  confirmationResult: "",
  confirm: "",
  recaptchaWidgetId: "",
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};

const VerifyPhoneNumber = (props) => {
  let [formField, setFormField] = useState("000000");
  let [activeField, setActiveField] = useState("first");
  let [fieldList, setFieldList] = useState(listOffields);
  let [isLoading, setIsLoading] = useState(false);
  let [timer, setTimer] = useState(60);
  let [intervalID, setIntervalID] = useState();
  let [active, setActive] = useState(false);
  let [final, setFinal] = useState(fire);
  let [sentOTP, setSentOTP] = useState();
  let [errorMessage, setErrorMessage] = useState("");
  let [successMessage, setSuccessMessage] = useState("");
  let [solvedCapcha, setSolvedCapcha] = useState(false);
  let [parentErrorMessage, setParentErrorMessage] = useState(props.errorMessage);

  initializeApp(firebaseConfig);
  const auth = getAuth();

  useEffect(() => {
    setIsLoading(true);
    resendTimer();
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  function returnLogin() {
    window.location.href = props.returnto;
  }

  function resendTimer() {
    startProcess();
    // sendOtp();
    let interval = setInterval(() => {
      setTimer((timer = timer - 1));
    }, 1000);
    setIntervalID(interval);
    setTimeout(() => {
      setActive(true);
      clearInterval(interval);
    }, 60000);
  }

  function startProcess() {
    const re = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {
          // console.log(response);
          setSolvedCapcha(true);
          // first create user account then send otp to number
          // sendOtp();
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": (response) => {
          // console.log(response);
          setSolvedCapcha(false);
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      auth
    );
    re.render().then((widgetId) => {
      re.reset(widgetId);
    });
    setFinal({ ...final, recaptchaVerifier: re });
    sendOtp(re);
  }

  const sendOtp = (v) => {
    let verify = v;
    const phoneNumber = `${props.mobileNumber}`;
    signInWithPhoneNumber(auth, phoneNumber, verify)
      .then((result) => {
        // @ts-ignore
        setSentOTP(result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage("There was an error sending otp");
      });
  };

  const ValidateOtp = async () => {
    setIsLoading(true);
    const otp = formField;
    if (otp === null || final === null) return;
    setErrorMessage("");
    // @ts-ignore
    await sentOTP
      .confirm(otp)
      .then((result) => {
        setSuccessMessage("Successfully verified phone number");
        props.successful();
        console.log(result);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage("Wrong OTP Code");
        console.log(err);
      });
  };

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    if (value == "") {
      return;
    }
    const name = target.name;
    const oldValue = formField;
    const position = parseInt(name) - 1;
    const newCode = setCharAt(oldValue, position, value);
    setFormField((formField = newCode));

    const nextFieldId = parseInt(name) + 1;
    const nextField = fieldList[nextFieldId];
    setActiveField(nextField);
  }

  function handleFinalInputChange(event) {
    const target = event.target;
    const value = target.value;
    if (value == "") {
      return;
    }
    const name = target.name;
    const oldValue = formField;
    const position = parseInt(name) - 1;
    const newCode = setCharAt(oldValue, position, value);
    setFormField((formField = newCode));

    ValidateOtp();
  }

  return (
    <>
      {solvedCapcha ? (
        <div className={classes.container}>
          <div className={classes.returnContainer}>
            <button className={classes.returnButton} onClick={returnLogin}>
              <Icon.ArrowLeft2 size="32" />
            </button>
          </div>
          <h1 className={classes.heading}>Verify Phone Number</h1>
          <div className={classes.textContainer}>
            <span>
              An OTP has been sent to your mobile number to verify your phone
              number
            </span>
            <p style={{ textAlign: "center" }}>Please input it below</p>
          </div>
          <form className={classes.formContainer} autoComplete="off">
            <input
              type="tel"
              name={"1"}
              disabled={isLoading}
              ref={(first) => first && activeField === "first" && first.focus()}
              required={true}
              autoComplete="new-password"
              onChange={handleInputChange}
              maxLength="1"
              className={classes.phonenumberfield}
            />
            <input
              type="tel"
              name={"2"}
              disabled={isLoading}
              ref={(second) =>
                second && activeField === "second" && second.focus()
              }
              required={true}
              autoComplete="new-password"
              maxLength="1"
              onChange={handleInputChange}
              className={classes.phonenumberfield}
            />
            <input
              type="tel"
              name={"3"}
              disabled={isLoading}
              ref={(third) => third && activeField === "third" && third.focus()}
              required={true}
              maxLength="1"
              autoComplete="new-password"
              onChange={handleInputChange}
              className={classes.phonenumberfield}
            />
            <input
              type="tel"
              name={"4"}
              ref={(forth) => forth && activeField === "forth" && forth.focus()}
              required={true}
              disabled={isLoading}
              maxLength="1"
              autoComplete="new-password"
              onChange={handleInputChange}
              className={classes.phonenumberfield}
            />
            <input
              type="tel"
              name={"5"}
              ref={(fifth) => fifth && activeField === "fifth" && fifth.focus()}
              disabled={isLoading}
              required={true}
              maxLength="1"
              autoComplete="new-password"
              onChange={handleInputChange}
              className={classes.phonenumberfield}
            />
            <input
              type="tel"
              name={"6"}
              ref={(sixth) => sixth && activeField === "sixth" && sixth.focus()}
              disabled={isLoading}
              required={true}
              maxLength="1"
              autoComplete="new-password"
              onChange={handleFinalInputChange}
              className={classes.phonenumberfield}
            />
          </form>
          <div id="recaptcha-container"></div>
          {isLoading ? (
            <div className={classes["loadingbutton"]}>
              <div className="sk-circle">
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
              </div>
            </div>
          ) : (
            <>
              {errorMessage !== "" && (
                <p
                  clpassName={classes.textDanger}
                  style={{ textAlign: "center" }}
                >
                  {errorMessage}
                </p>
              )}
              {parentErrorMessage !== "" && (
                <p
                  clpassName={classes.textDanger}
                  style={{ textAlign: "center" }}
                >
                  {parentErrorMessage}
                </p>
              )}
              {successMessage !== "" && (
                <p
                  clpassName={classes.textSuccess}
                  style={{ textAlign: "center" }}
                >
                  {successMessage}
                </p>
              )}
              <p style={{ marginTop: 50, textAlign: "center" }}>
                {active ? (
                  <button className={classes.continue} onClick={resendTimer}>
                    Resend OTP
                  </button>
                ) : (
                  <>
                    Resend code in{" "}
                    <span className={classes.textPrimary}>{timer}</span>
                  </>
                )}
              </p>
            </>
          )}
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.returnContainer}>
            <button className={classes.returnButton} onClick={returnLogin}>
              <Icon.ArrowLeft2 size="32" />
            </button>
          </div>
          <h1 className={classes.heading}>Solve The reCAPTCHA</h1>
          <div className={classes.textContainer}>
            <span>Solve the reCAPTCHA to continue</span>
          </div>
          <div className={classes.captchaContainer}>
            <div id="recaptcha-container"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyPhoneNumber;

import React, { useState, useEffect } from "react";
import classes from "./Earnings.module.css";
import API from "../../../../services/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Header from "../../../Header/Header";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import MobileCard from "./MobileCard/MobileCard";
import { Helmet } from "react-helmet";
import Pagination from "../../../UI/Pagination/Pagination";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const todayDate = new Date().toUTCString;

const labels = [];

export const data = {
  labels,
  datasets: [
    {
      label: "Earnings",
      data: [],
      backgroundColor: "rgba(63, 132, 132, 1)",
      borderRadius: 10,
    },
  ],
};

function getWeekNumber(date) {
  const dateTime = new Date(date);
  var d = new Date(
    Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
  );
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}

function convertTime(date) {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
}

function returnClass() {
  const arr = [
    classes["tag-danger"],
    classes["tag-success"],
    classes["tag-info"],
    classes["tag-warning"],
    classes["table-filter"],
  ];
  const item = Math.floor(Math.random() * 4);
  return arr[item];
}

const Earnings = (props) => {
  let [earnings, setEarnings] = useState([]);
  let [tableData, setTableData] = useState([]);
  let [workAbleData, setWorkAbleData] = useState([]);
  let [weekList, setWeekList] = useState([]);
  let [currentWeekEarnings, setCurrentWeekEarnings] = useState("0.00");
  let [loading, setLoading] = useState(true);
  let [totalDeducted, setTotalDeducted] = useState(0);
  let [totalAmountPaidOut, setTotalAmountPaidOut] = useState(0);
  let [graphData, setGraphData] = useState(data);
  let [downloading, setDownloading] = useState(false);
  // let [getTodayDate] = useState(todayDate);

  function currentDate() {
    return new Date().toUTCString;
  }

  useEffect(() => {
    API.get(`/dashboard/earnings/driver/all`)
      .finally(() => {
        setLoading(false);
      })
      .then((res) => {
        setEarnings(res.data.results.earnings);
        setWorkAbleData(res.data.results.earnings);
        if (res.data.results.earnings.length > 0) {
          getChartData(res.data.results.earnings);
        }
      });
  }, []);

  useEffect(() => {
    let calTotalDeducted = 0;
    let calTotalAmountPaidOut = 0;
    tableData.forEach((value) => {
      if (value.deduction) {
        const diff = parseFloat(value.amount) - parseFloat(value.payout_amount);
        calTotalDeducted = calTotalDeducted + diff;
      }
      calTotalAmountPaidOut =
        calTotalAmountPaidOut + parseFloat(value.payout_amount);
    });
    setTotalDeducted(calTotalDeducted);
    setTotalAmountPaidOut(calTotalAmountPaidOut);
  }, [tableData]);

  function printEarnings() {
    setDownloading(true);
    const input = document.getElementById("earningsReport");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("earnings.pdf");
      setDownloading(false);
    });
  }

  function getChartData(newData) {
    let weekGroup = new Map();
    newData.map((value) => {
      // const month = monthNames[new Date(value.payout_date).getMonth()];
      const week = getWeekNumber(value.payout_date);
      if (!weekGroup.has(week)) {
        weekGroup.set(week, [value]);
        // monthGroup[month].push(value);
      } else {
        // monthGroup[month] = [];
        const weekG = weekGroup.get(week);
        weekG.push(value);
        weekGroup.set(week, weekG);
      }
      return weekGroup;
    });
    getWeeklyData(weekGroup);
  }

  function getWeeklyData(weeklyData) {
    let actualWeekGroup = new Map();
    const actualCurrentFullDate = new Date();
    weeklyData.forEach((value, key) => {
      const currentWeek = key;
      const map = new Map();
      actualWeekGroup.set(currentWeek, map);
      value.forEach((value) => {
        const dayNames = ["Mon", "Tues", "Wed", "Thur", "Fri", "Sat", "Sun"];
        const dayOfWeek = dayNames[new Date(value.payout_date).getUTCDay()];
        const week = actualWeekGroup.get(currentWeek);
        if (!week.has(dayOfWeek)) {
          const int = parseFloat(value.payout_amount);
          week.set(dayOfWeek, int);
        } else {
          const weekData = week.get(dayOfWeek);
          const int = weekData + parseFloat(value.payout_amount);
          week.set(dayOfWeek, int);
        }

        if (
          new Date(value.payout_date).getUTCFullYear() ===
            actualCurrentFullDate.getUTCFullYear() &&
          currentWeek === getWeekNumber(actualCurrentFullDate)
        ) {
          let total = 0;
          week.forEach((value) => {
            total = parseInt(total) + parseInt(value);
          });
          var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
          });
          const currency = formatter.format(total);
          setCurrentWeekEarnings(currency ? currency : "0.00");
        }
        actualWeekGroup.set(currentWeek, week);
      });
    });
    const array = Array.from(actualWeekGroup, ([name, value]) => ({
      name,
      value,
    }));
    setWeekList(array);
    setGraph(array[0].name, array);
  }

  function setGraph(showWeek, arr) {
    // debugger;
    const newData = graphData;
    let days = [];
    var amount = [];
    const week =
      weekList.length > 0
        ? weekList.filter((value, index) => {
            return value.name === showWeek;
          })
        : arr.filter((value, index) => {
            return value.name === showWeek;
          });
    // const currentWeek = week[0];
    const newArray = Array.from(week[0].value, ([name, value]) => ({
      name,
      value,
    }));
    // console.log(week);
    // days.push(newArray[0].name);
    // amount.push(newArray[0].value);
    newArray.forEach((value, key) => {
      days.push(value.name);
      amount.push(value.value);
    });
    newData.labels = days;
    newData.datasets[0].data.splice(0, newData.datasets[0].data.length);
    newData.datasets[0].data.push(...amount);
    setGraphData({ ...newData });
    // debugger;
  }

  function onChangeWeek(e) {
    let { name, value } = e.target;
    setGraph(parseInt(value));
  }

  function onSearch(e) {
    let { name, value } = e.target;
    const result = earnings.filter((item) => {
      const hayStack =
        item.amount.toString().toLowerCase() +
        convertTime(item.created_at) +
        item.trips_id.toString().toLowerCase() +
        item.type.toString().toLowerCase() +
        convertTime(item.payout_date) +
        item.payout_amount.toString().toLowerCase() +
        item.status.toString().toLowerCase();
      return hayStack.indexOf(value.toString().toLowerCase()) > -1;
    });
    setWorkAbleData(result);
  }

  function paginateTable(data) {
    setTableData(data);
  }

  return (
    <div>
      <Helmet>
        <title>iDriva - Earnings</title>
      </Helmet>
      <div className={classes["flex-container"]}>
        <div className={classes["heading-container"]}>
          <div className={classes["title"]}>
            <p>Earnings</p>
          </div>
          <div className={classes["sub-title"]}>
            <p>
              Total Earning for the week - &nbsp;&nbsp;&nbsp;
              <b>#{currentWeekEarnings}</b>
            </p>
          </div>
          <div className={classes["sub-title-mobile"]}>
            <p>
              Total Earning for the week <br />
              <strong className={classes["mt-2"]}>
                #{currentWeekEarnings}
              </strong>
            </p>
          </div>
        </div>
        {!loading ? (
          <div className={classes["main-view-container"]}>
            <div
              className={
                classes["chart-container"] +
                " " +
                (data.datasets[0].data.length > 0 &&
                  classes["chart-container-height"])
              }
            >
              {data.datasets[0].data.length > 0 ? (
                <>
                  <div className={classes["edge-marker"]} />
                  <div className={classes["chart-title"]}>
                    <h6>Showing Earning Report for this week</h6>
                  </div>
                  <div className={classes["chart-subtitle"]}>
                    <p>Your Earning Overview</p>
                  </div>
                  <select
                    className={classes["chart-select"]}
                    onChange={onChangeWeek}
                  >
                    {weekList.length > 0 &&
                      weekList.map((value) => {
                        return (
                          <option key={value.name} value={value.name}>
                            Week {value.name}
                          </option>
                        );
                      })}
                  </select>
                  <div className={classes["bar-chart"]}>
                    <Bar options={options} data={graphData} />
                  </div>
                </>
              ) : (
                <div className={classes["no-result"]}>
                  No Earnings to display
                </div>
              )}
            </div>
            <MobileCard
              heading={"Total Earned"}
              subtitle={`#${currentWeekEarnings}`}
            />
            <div className={classes["mt-5"]}></div>
            <MobileCard heading={"Total Payout"} subtitle={`#0.00`} />
            <div className={classes["table-container"]}>
              <div className={classes["table-controls"]}>
                <div className={classes["table-title"]}>Transactions</div>
                <div className={classes["align-controls"]}>
                  {/*<select className={classes['table-filter']}>
                                <option>Filter</option>
                                <option>Date</option>
                                <option>Transactions</option>
                                <option>Payouts</option>
                            </select>*/}
                  <input
                    type="text"
                    className={classes["table-filter"]}
                    placeholder="Search for Type, Date, Deduction etc."
                    onKeyUp={onSearch}
                  />
                  {tableData.length > 0 && (
                    <button
                      className={classes["table-button"]}
                      onClick={printEarnings}
                      disabled={downloading}
                    >
                      {downloading ? (
                        <div className={classes["downloading-button"]}>
                          <div className="sk-circle">
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                            <div className="sk-circle-dot"></div>
                          </div>
                        </div>
                      ) : (
                        "Download"
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className={classes["table-flex"]}>
                <table className={classes["table"]}>
                  <thead>
                    <tr>
                      <th className={classes["tags"]}></th>
                      <th>Type</th>
                      <th>Trip ID</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Deduction</th>
                      <th>Payout Date</th>
                      <th>Payout</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {/* className="animate__fadeInLeftBig" */}
                  <tbody>
                    {tableData.length > 0 ? (
                      <>
                        {tableData.map((value) => {
                          return (
                            <tr
                              className="animate__animated animate__fadeInBottomLeft"
                              key={value.id}
                            >
                              <td className={classes["tags"]}>
                                <span className={returnClass()} />
                              </td>
                              <td className={classes["auto-capitalize"]}>
                                {value.type}
                              </td>
                              <td>{value.trips_id}</td>
                              <td>{convertTime(value.created_at)}</td>
                              <td>#{value.amount}</td>
                              <td>{value.deduction ? "YES" : "NO"}</td>
                              <td>{convertTime(value.payout_date)}</td>
                              <td>#{value.payout_amount}</td>
                              <td
                                className={
                                  value.status === "PAID"
                                    ? classes["txt-success"]
                                    : classes["txt-danger"]
                                }
                              >
                                {value.status}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8} className={classes["no-result"]}>
                          {" "}
                          No result found{" "}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination fullData={workAbleData} paginatedData={paginateTable} />
            {/* d-none */}
            <div className={classes["d-none"]}>
              <div
                id="earningsReport"
                className={classes["printout-table-container"]}
              >
                <h1 className={classes["printout-heading"]}>
                  Total Earnings Breakdown
                </h1>
                <div className={classes["printout-info"]}>
                  <h4 className={classes["printout-username"]}>
                    Abdul Afex Kazeem
                  </h4>
                  <h4 className={classes["printout-invoicetitle"]}>
                    Invoice Date:
                  </h4>
                  <h4 className={classes["printout-invoicedate"]}>
                    {new Date().toLocaleString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </h4>
                </div>
                <table className={classes["printout-table"]}>
                  <thead>
                    <tr>
                      <th className={classes["printout-table-first"]}>
                        Description
                      </th>
                      <th>Amount Earned</th>
                      <th>Date</th>
                      <th>Amount Payed out</th>
                      <th className={classes["printout-table-last"]}>
                        Payout Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes["printout-table-body"]}>
                    {earnings.length > 0 &&
                      earnings.map((value) => {
                        return (
                          <tr key={value.id}>
                            <td
                              className={
                                classes["printout-table-first"] +
                                " " +
                                classes["auto-capitalize"]
                              }
                            >
                              {value.type}
                            </td>
                            <td>#{value.amount}</td>
                            <td>{convertTime(value.created_at)}</td>
                            <td>#{value.payout_amount}</td>
                            <td className={classes["printout-table-last"]}>
                              {convertTime(value.payout_date)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className={classes["subtotal-table"]}>
                  <div className={classes["sub-item"]}>
                    <span className={classes["total-deduction"]}>
                      Total Deduction
                    </span>
                    <span className={classes["total-deduction-amount"]}>
                      #{totalDeducted}
                    </span>
                  </div>
                  <div
                    className={
                      classes["sub-item"] +
                      " " +
                      classes["border-bottom"] +
                      " " +
                      classes["pb-3"]
                    }
                  >
                    {/* <span className={classes["total-deduction"]}>
                      Tax Deduction
                    </span>
                    <span className={classes["total-deduction-amount"]}>
                      #2,000
                    </span> */}
                  </div>
                  <div className={classes["sub-item"]}>
                    <span className={classes["total-deduction"]}>
                      Total Amount Paid out
                    </span>
                    <span className={classes["total-deduction-amount"]}>
                      #{totalAmountPaidOut}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes["preloader"]}>
            <div className="sk-circle">
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
              <div className="sk-circle-dot"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Earnings;

import React, { useEffect, useState } from "react";
import classes from "./Pagination.module.css";
import * as Icon from "iconsax-react";

/**
 * JSX Component for handeling pagination control
 *
 * @param {object} props
 * @param {array} props.fullData
 * @param {integer} props.perPage
 * @param {(data) => {}} props.paginatedData
 * @returns
 */
const Pagination = (props) => {
  let [fullData, setFullData] = useState(props.fullData);
  let [length, setLength] = useState(props.perPage ? props.perPage : 10);
  let [indexes, setIndexes] = useState(1);
  let [activeIndex, setActiveIndex] = useState(1);
  let [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    handleOperations();
  }, [fullData]);

  function handleOperations() {
    const allData = props.fullData;
    paginateData(allData);
    calculateIndexes(fullData);
  }

  /**
   * 
   * @param {array} data 
   */
  function calculateIndexes(data) {
    var edata = parseInt(data.length) / parseInt(length);
    setIndexes(edata > parseInt(edata) ? parseInt(edata) + 1 : parseInt(edata));
  }

  /**
   * 
   * @param {array} data 
   * @param {integer} index 
   */
  function paginateData(data, index = 1) {
    let end = parseInt(length) * parseInt(index);
    let begin = parseInt(end) - parseInt(length);
    let newPagination = [];
    for (var i = begin; i < end; i++) {
      if (data[i]) {
        newPagination.push(data[i]);
      }
    }
    setPaginatedData(newPagination);
    props.paginatedData(newPagination);
    setActiveIndex(index);
  }

  /**
   * 
   * @param {bool} isForward 
   */
  function changePagination(isForward = true) {
    var currentIndex = isForward
      ? parseInt(activeIndex) + 1
      : parseInt(activeIndex) - 1;
    paginateData(fullData, currentIndex);
  }

  return (
    <div className={classes["pagination-container"]}>
      {props.fullData.length > 0 && (
        <>
          {props.fullData != fullData && setFullData(props.fullData)}
          <div className={classes["pagination-information"]}>
            <span className={classes["pagination-information-text"]}>
              Showing {paginatedData.length} from {props.fullData.length}{" "}
              results
            </span>
          </div>
          <div className={classes["pagination-control"]}>
            <div className={classes["pagination-control-container"]}>
              {activeIndex > 1 && (
                <div className={classes["forward-arrow-container"]}>
                  <Icon.Previous
                    onClick={() => {
                      changePagination(false);
                    }}
                  />
                </div>
              )}
              <div className={classes["navigations-container"]}>
                {[...Array(indexes)].map((e, i) => {
                  return (
                    <span
                      key={i + 1}
                      className={
                        activeIndex == i + 1
                          ? classes["active-pagination"]
                          : classes["inactive-pagination"]
                      }
                      onClick={() => {
                        paginateData(fullData, i + 1);
                      }}
                    >
                      {i + 1}
                    </span>
                  );
                })}
              </div>
              {activeIndex != indexes && indexes > 1 && (
                <div className={classes["backward-arrow-container"]}>
                  <Icon.Next
                    onClick={() => {
                      changePagination(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;

import classes from './ChangePasswordForm.module.css';
import React, { useState, useEffect } from "react";
import API from "../../../../../services/api";
import MobilePix from "../../../../assets/images/Group Picture.png";

const data = {
    reset_password_token: "",
    password: "",
    password_confirmation: "",
  };

const ChangePasswordForm = (props) => {
    let [formField, setFormField] = useState(data);
    let [submitting, setSubmitting] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      const token = props.token
      setFormField({ ...formField, reset_password_token: token });
    },[]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setFormField({ ...formField, [name]: value });
    }

    function onSubmit(e) {
        e.preventDefault();
        setSubmitting(true);
        setErrorMessage("");
        if (formField.password === "" || formField.password_confirmation === "") {
          setErrorMessage("Fill all fields");
          setSubmitting(false);
          return;
        }
        API.post(`/auth/reset/password`, {
          ...formField,
        })
          .finally(() => {
            setSubmitting(false);
          })
          .then((response) => {
            if (response.data.error) {
              setErrorMessage(response.data.message);
              return;
            }
            props.successful();
          })
          .catch((error) => {
            if (error.response.data.error) {
              setErrorMessage(error.response.data.message);
              return;
            }
            setErrorMessage(error.message);
          });
      }


    return (
        <div className={classes.container}>
      <div className={classes.mobilePixContainer}>
        <img src={MobilePix} alt="Img" className={classes.mobilePix} />
      </div>
      <h1 className={classes.heading}>Reset Password</h1>
      <p className={classes.subheading} >Reset your password, to avoid intruder to access your accout</p>
      <form onSubmit={onSubmit} autoComplete="off">
        <input
          type="password"
          name={"password"}
          required={true}
          value={formField.password}
          onChange={handleInputChange}
          className={classes.passwordfield}
          autoComplete="new-password"
          placeholder={"Password"}
        />
        <input
            required={true}
            type="password"
            value={formField.password_confirmation}
            name={"password_confirmation"}
            className={classes.passwordconfirmationfield}
            autoComplete="new-password"
            onChange={handleInputChange}
            placeholder={"Confirm Password"}
        />
        {errorMessage !== "" && (
          <p className={classes.errormessage}>{errorMessage}</p>
        )}
        <button
          disabled={submitting}
          type={"submit"}
          className={classes.continue}
        >
          {submitting ? (
            <div className={classes["loadingbutton"]}>
              <div className="sk-circle">
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
              </div>
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </form>
    </div>
    )
}

export default ChangePasswordForm;
import React from "react";
import { Link } from "react-router-dom";
import classes from "./AuthHome.module.css";
import LandingBackground from "../../UI/Landing/LandingBackground";
import MobilePix from "../../assets/images/Group Picture.png";
import { useNavigate } from "react-router-dom";

const AuthHome = (props) => {
  let navigate = useNavigate();

  const login = () => {
    navigate("/auth/login");
  };

  return (
    <div className={classes.home}>
      <LandingBackground className={classes.hideBackground} />
      <div className={classes.container}>
        <div className={classes.mobilePixContainer}>
          <img src={MobilePix} alt="Img" className={classes.mobilePix} />
        </div>
        <h1 className={classes.heading}>Welcome to iDriva</h1>
        <p className={classes.subtitle}>
          Specially designed for Truck riders, Bus riders, Dispatch riders to
          help them track their trips, earning, kilometers. etc.
        </p>
        {/* <button disabled={true} className={classes.staffidbutton}>Continue with Staff ID</button> */}
        <button className={classes.phonennumberbutton} onClick={login}>
          Continue with Phone Number
        </button>
        <p className={classes.signup}>
          New user? <Link to="/auth/register">Sign up</Link>
        </p>
      </div>
    </div>
  );
};

export default AuthHome;

import React from "react";
import classes from "./CSupport.module.css";
import { Helmet } from "react-helmet";

const CSupport = () => {
  return (
    <>
      <Helmet>
        <title>iDriva - Customer Support</title>
      </Helmet>
      <div className={classes["container"]}>
        <div className={classes["header-container"]}>
          <h2 className={classes["title"]}>Get in touch with us</h2>
        </div>
        <div className={classes["sub-header-container"]}>
          <p className={classes["sub-title"]}>
            In the event if you have delivery issues or need help with
            information and guidiance. You can make use of any of our support
            channels below.
          </p>
        </div>
      </div>
    </>
  );
};

export default CSupport;

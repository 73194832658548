import React, { useEffect, useState } from "react";
import classes from "./Logout.module.css";
import AuthConfirmations from "../../../UI/AuthConfirmations/AuthConfirmations";
import { Helmet } from "react-helmet";

const Logout = (props) => {
  let [intervalID, setIntervalID] = useState();
  let [timing, setTiming] = useState(3);

  useEffect(() => {
    let interval = setInterval(() => {
      setTiming(timing - 1);
    }, 1000);
    setIntervalID(interval);
    if (timing <= 0) {
      clearInterval(interval);
    }
    window.localStorage.removeItem("token");
    setTimeout(() => {
      window.location.href = "/dashboard";
    }, 3000);
    return () => {
      clearInterval(intervalID);
    };
  }, [timing]);
  return (
    <div>
      <Helmet>
        <title>iDriva - Logout</title>
      </Helmet>
      <AuthConfirmations
        heading={`Bye..`}
        message={`You will be automatically redirected in ${timing}`}
      />
    </div>
  );
};

export default Logout;

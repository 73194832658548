import React from "react";
import classes from "./Security.module.css";
import SecurityCard from "./SecurityCard/SecurityCard";

const Security = () => {
  return (
    <div className={classes["container"]}>
      <p className={classes["title"]}>Security</p>
      <p className={classes["sub-title"]}>
        Change account security configuration.
      </p>
      <div className={classes["border-line"]}></div>
      <SecurityCard
        heading={"Save Login Information"}
        description={
          "We”ll remember the login info for techbabby, so you won’t need to enterr it on your cloud devives"
        }
      />
      <div className={classes["mt-3"]}></div>
      <SecurityCard
        heading={"2FA Authentication "}
        description={
          "2FA authentication protects your account by requiring a logi code when you log in on a device we dont recognise"
        }
      />
    </div>
  );
};

export default Security;

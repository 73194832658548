import React, { useState, useEffect } from "react";
import API from "../../../../services/api";
import Man from "../../../assets/images/freepik.png";
import * as Icon from "iconsax-react";
import Woman from "../../../assets/images/freepik2.png";
import User from "../../../assets/images/user.png";
import LearnMore from "../../../assets/images/learnmore.png";
import RecentActivities from "./recent-activities/RecentActivities";
import AssignedTrips from "./assigned-trips/AssignedTrips";
import classes from "./Home.module.css";
import { NavLink } from "react-router-dom";
import Avatar from "react-avatar";
import { Helmet } from "react-helmet";
import Pagination from "../../../UI/Pagination/Pagination";

function getWeekNumber(date) {
  const dateTime = new Date(date);
  var d = new Date(
    Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
  );
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}

const randomColors = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Home = (props) => {
  let [loading, setLoading] = useState(true);
  let [weeklyEarnings, setWeeklyEarnings] = useState(0);
  let [weeklyDistance, setWeeklyDistance] = useState(0);
  let [assignedTrips, setAssignedTrips] = useState([]);
  let [viewingTrips, setViewingTrips] = useState([]);
  let [userData, setUserData] = useState();
  let [currentWeek, setCurrentWeek] = useState(getWeekNumber(new Date()));
  let [ppColor] = useState(randomColors());
  let [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    getAllEarnings();
    getAllTrips();
    getUserProfile();
  }, []);

  function getUserProfile() {
    API.get("/dashboard/user/profile/")
      .finally(() => {
        setLoading(false);
      })
      .then((response) => {
        setUserData(response.data.results.user);
      });
  }

  function getAllTrips() {
    API.get(`/dashboard/trips/driver/assigned`)
      .finally(() => {
        setLoading(false);
      })
      .then((res) => {
        const data = res.data.results.trips;
        let assignedTrip = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].state == null && data[i].status == "assigned") {
            assignedTrip.push(data[i]);
          }
        }
        setAssignedTrips(assignedTrip);
        setViewingTrips(assignedTrip);
        if (assignedTrip.length > 0) {
          calDistance(assignedTrip);
        }
      });
  }

  function getAllEarnings() {
    API.get(`/dashboard/earnings/driver/all`)
      .finally(() => {
        setLoading(false);
      })
      .then((res) => {
        if (res.data.results.earnings.length > 0) {
          calEarnings(res.data.results.earnings);
        }
      });
  }

  function calEarnings(data) {
    let totalEarnings = 0;
    data.forEach((value) => {
      const weekValue = getWeekNumber(value.payout_date);
      const currentWeek = getWeekNumber(new Date());
      if (weekValue === currentWeek) {
        totalEarnings += value.payout_amount;
      }
    });
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    const currency = formatter.format(totalEarnings);
    setWeeklyEarnings(currency);
  }

  function calDistance(data) {
    let totalDistance = 0;
    data.forEach((value) => {
      const weekValue = getWeekNumber(value.payout_date);
      const currentWeek = getWeekNumber(new Date());
      if (weekValue === currentWeek) {
        totalDistance += parseFloat(value.distance);
      }
    });
    setWeeklyDistance(totalDistance);
  }

  function onSearch(e) {
    let { name, value } = e.target;
    const result = assignedTrips.filter((item) => {
      const hayStack = item.trip_code.toString().toLowerCase();
      return hayStack.indexOf(value.toString().toLowerCase()) > -1;
    });
    setViewingTrips(result);
  }

  /**
   *
   * @param {array} data
   */
  function handlePaginationCallBack(data) {
    setDataToShow(data);
  }

  return (
    <div>
      <Helmet>
        <title>iDriva - Home</title>
      </Helmet>
      {!loading ? (
        <div className={classes["main-container"]}>
          <div className={classes["left-side"]}>
            <div
              className={
                classes["search-profile-right-side-container"] +
                " " +
                classes["show-in-device"]
              }
            >
              <div className={classes["search-field"]}>
                <input
                  type="text"
                  className={classes["search"]}
                  name="search trip"
                  onKeyUp={onSearch}
                  placeholder="Search for Trip Number"
                ></input>
                <Icon.SearchNormal />
              </div>
              <div className={classes["profile-actions-container"]}>
                <NavLink
                  className={classes["picture-anchor"]}
                  to="/dashboard/user-profile"
                >
                  {/* <img src={User} alt="User"></img> */}
                  {userData?.profile_picture_image ? (
                    <img
                      className={classes["menu-profile-picture"]}
                      src={`data:image/jpeg;base64,${userData?.profile_picture_image}`}
                      alt="profilePicture"
                    />
                  ) : (
                    <Avatar
                      size="50"
                      color={ppColor}
                      name={`${
                        userData?.full_name
                          ? userData?.full_name
                          : userData?.phone_number
                      }`}
                      round={true}
                    />
                  )}
                </NavLink>

                <Icon.Notification />
              </div>
            </div>
            <div className={classes["name-container"]}>
              <p className={classes["name-font"]}>
                Welcome,{" "}
                <strong className={classes["break-name"]}>
                  {userData?.full_name
                    ? userData?.full_name
                    : userData?.phone_number}
                </strong>
              </p>
            </div>
            <div className={classes["greeting-box"]}>
              <div className={classes["greeting-box-left-side"]}>
                <p>Good Job! you have covered</p>
                <h3>{weeklyDistance}KM this week</h3>
              </div>
              <div className={classes["greeting-box-right-side"]}>
                <img className={classes["man"]} src={Man} alt="MAN" />
                <img className={classes["woman"]} src={Woman} alt="WOMAN" />
              </div>
            </div>
            <div className={classes["earnings-message"]}>
              <div className={classes["earnings-message-left-side"]}>
                <p>Total Earning for Week {currentWeek}</p>
                <h3>{weeklyEarnings}</h3>
              </div>
              <div className={classes["earnings-message-right-side"]}>
                <div>
                  <Icon.Send2 />
                </div>
              </div>
            </div>
            <div className={classes["hide-in-device"]}>
              <div className={classes["recent-text-heading"]}>
                <h3>Recent Activities</h3>
              </div>
              {/*<RecentActivities />*/}
              {/*<RecentActivities />*/}
              <div className={classes["mb-5"]}>No Recent Activities</div>
              <div className={classes["mini-trip-container"]}>
                <h3>Trip-</h3>
                {/*<p>You have arrived at your drop off location!!</p>*/}
                <p>No description</p>
              </div>
              <div className={classes["mini-trip-container"]}>
                <h3>HR Service</h3>
                <p>Your weekly payout has been made</p>
              </div>
            </div>
          </div>
          <div className={classes["right-side"]}>
            <div
              className={
                classes["search-profile-right-side-container-main"] +
                " " +
                classes["hide-in-device"]
              }
            >
              <div className={classes["search-field"]}>
                <Icon.SearchNormal />
                <input
                  type="text"
                  className={classes["search"]}
                  name="search trip"
                  onKeyUp={onSearch}
                  placeholder="Search for Trip Number"
                ></input>
              </div>
              <div className={classes["profile-actions-container"]}>
                <Icon.Notification />
                <NavLink
                  className={classes["picture-anchor"]}
                  to="/dashboard/user-profile"
                >
                  {/* <img src={User} alt="User"></img> */}
                  {userData?.profile_picture_image ? (
                    <img
                      className={classes["menu-profile-picture"]}
                      src={`data:image/jpeg;base64,${userData?.profile_picture_image}`}
                      alt="profilePicture"
                    />
                  ) : (
                    <Avatar
                      size="50"
                      color={ppColor}
                      name={`${
                        userData?.full_name
                          ? userData?.full_name
                          : userData?.phone_number
                      }`}
                      round={true}
                    />
                  )}
                </NavLink>
              </div>
            </div>
            {dataToShow.length > 0 ? (
              (dataToShow.map((value) => {
                return <AssignedTrips key={value.id} trip={value} />;
              })
              )
            ) : (
              <div className={classes["exception"]}>You have no Trip</div>
            )}
            <Pagination
                  fullData={viewingTrips}
                  paginatedData={handlePaginationCallBack}
                  perPage={3}
                />
            <div className={classes["learn-more-container"]}>
              <div className={classes["learn-more-left"]}>
                <h3>Community for Drivers</h3>
                <p>
                  Learn more about our driver community, a platform for Drivers
                </p>
                <button>Learn More</button>
              </div>
              <div className={classes["learn-more-right"]}>
                <img src={LearnMore} alt="LEARN MORE" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes["preloader"]}>
          <div className="sk-circle">
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;

import React, { Fragment, useState } from "react";
import classes from "./LoginForm.module.css";
import { Link } from "react-router-dom";
import API from "../../../../../services/api";
import MobilePix from "../../../../assets/images/Group Picture.png";

const data = {
  phone_number: "",
  password: "",
};

const LoginForm = (props) => {
  let [formField, setFormField] = useState(data);
  let [submitting, setSubmitting] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");
  let [parentErrorMessage, setParentErrorMessage] = useState(props.errorMessage);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormField({ ...formField, [name]: value });
  }

  function onSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage("");
    if (formField.password === "" || formField.phone_number === "") {
      setErrorMessage("Fill all fields");
      setSubmitting(false);
      return;
    }
    API.post(`/auth/login/ef12ee52-2ffb-499f-b0a0-fed8443205ad`, {
      ...formField,
    })
      .finally(() => {
        setSubmitting(false);
      })
      .then((response) => {
        if (response.data.error) {
          setErrorMessage(response.data.message);
          return;
        }
        props.successful(response.data.results);
      })
      .catch((error) => {
        if (error.response.data.error) {
          setErrorMessage(error.response.data.message);
          return;
        }
        setErrorMessage(error.message);
      });
  }

  return (
    <div className={classes.container}>
      <div className={classes.mobilePixContainer}>
        <img src={MobilePix} alt="Img" className={classes.mobilePix} />
      </div>
      <h1 className={classes.heading}>Welcome to iDriva</h1>
      <form onSubmit={onSubmit} autoComplete="off">
        <input
          type="tel"
          name={"phone_number"}
          required={true}
          value={formField.phone_number}
          onChange={handleInputChange}
          className={classes.phonenumberfield}
          placeholder={"Phone Number"}
        />
        <input
          type="password"
          name={"password"}
          required={true}
          value={formField.password}
          onChange={handleInputChange}
          className={classes.passwordfield}
          placeholder={"Password"}
        />
        {errorMessage !== "" && (
          <p className={classes.errormessage}>{errorMessage}</p>
        )}
        {parentErrorMessage !== "" && (
          <p className={classes.errormessage}>{parentErrorMessage}</p>
        )}
        <button
          disabled={submitting}
          type={"submit"}
          className={classes.continue}
        >
          {submitting ? (
            <div className={classes["loadingbutton"]}>
              <div className="sk-circle">
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
              </div>
            </div>
          ) : (
            "Continue"
          )}
        </button>
        <p className={classes.forgetPassword}>
          Forget <Link to="/auth/forget-password">Password?</Link>
        </p>
        <p className={classes.signup}>
          New user? <Link to="/auth/register">Sign up</Link>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;

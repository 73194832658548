import React from "react";
import classes from "./Notification.module.css";

const Notification = () => {
  return (
    <div className={classes["container"]}>
      <p className={classes["title"]}>Notification</p>
      <div className={classes["item-container"]}>
        <p className={classes["title-left"]}>T-12345D</p>
        <p className={classes["title-end"]}>trip has been completed</p>
      </div>
      <div className={classes["item-container"]}>
        <p className={classes["title-left"]}>HR Services</p>
        <p className={classes["title-end"]}>Your weekly payout has been made</p>
      </div>
      <div className={classes["item-container"]}>
        <p className={classes["title-left-message"]}>You have a message from </p>
        <p className={classes["title-end-message"]}>Admin 1 </p>
      </div>
    </div>
  );
};
export default Notification;

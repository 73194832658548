import React, { useState } from "react";
import classes from "./Mobile.module.css";
import Avatar from "react-avatar";
import * as Icon from "iconsax-react";
import { useNavigate } from "react-router-dom";

const randomColors = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const UserProfileMobile = (props) => {
  const navigate = useNavigate();
  let [ppColor] = useState(randomColors());

  return (
    <div className={classes["container"]}>
      <h1 className={classes["title"]}>User Profile</h1>
      <div
        className={classes["user-information"]}
        onClick={() => props.changeView("profile_settings")}
      >
        <div className={classes["image-container"]}>
          {props.userData.profile_picture_image ? (
            <img
              className={classes["profile-picture"]}
              src={`data:image/jpeg;base64,${props.userData.profile_picture_image}`}
              alt="profilePicture"
            />
          ) : (
            <Avatar
              size="50"
              color={props.ppColor}
              name={`${
                props.userData.full_name
                  ? props.userData.full_name
                  : props.userData.phone_number
              }`}
              round={true}
            />
          )}
        </div>
        <div className={classes["user-description"]}>
          <p className={classes["user-name"]}>User</p>
          <p className={classes["name"]}>{props.userData.full_name}</p>
        </div>
        <div className={classes["trail"]}>
          <Icon.ArrowRight2 />
        </div>
      </div>
      <div className={classes["menu"]}>
        <div className={classes["item"]}>
          <div className={classes["item-head"]}>
            <Icon.Messages />
          </div>
          <div className={classes["item-description"]}>
            <p>Messages</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
        <div className={classes["item"]}>
          <div className={classes["item-head"]}>
            <Icon.Information />
          </div>
          <div className={classes["item-description"]}>
            <p>Violation</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
        <div className={classes["item"]}>
          <div className={classes["item-head"]}>
            <Icon.MedalStar />
          </div>
          <div className={classes["item-description"]}>
            <p>Badges</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
        <div className={classes["item"]}>
          <div className={classes["item-head"]}>
            <Icon.Notification />
          </div>
          <div className={classes["item-description"]}>
            <p>Notifications</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
        <div className={classes["item"]}>
          <div className={classes["item-head"]}>
            <Icon.SecuritySafe />
          </div>
          <div className={classes["item-description"]}>
            <p>Security</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
        <div
          className={classes["item"]}
          onClick={() => navigate("/dashboard/c-support")}
        >
          <div className={classes["item-head"]}>
            <Icon.MessageQuestion />
          </div>
          <div className={classes["item-description"]}>
            <p>Customer Support</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
        <div className={classes["item"]} onClick={() => navigate("/logout")}>
          <div className={classes["item-head"] + " " + classes["sign-out"]}>
            <Icon.LogoutCurve />
          </div>
          <div className={classes["item-description"]}>
            <p className={classes["sign-out"]}>Sign Out</p>
          </div>
          <div className={classes["item-trail"]}>
            <Icon.ArrowRight2 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileMobile;

import React from "react";
import Avatar from "react-avatar";
import classes from "./UserProfile.module.css";
import profilePicture from "../../../assets/images/profilePicture.png";
import * as Icon from "iconsax-react";
import UserProfileInformation from "./UserProfileInformation/UserProfileInformation";
import Security from "./Security/Security";
import Notification from "./Notification/Notification";
import { useState, useEffect } from "react";
import API from "../../../../services/api";
import AcceptTrip from "../../../UI/Assigned/AcceptTrip/AcceptTrip";
import UserProfileMobile from "./Mobile/Mobile";
import ProfileSettings from "./Mobile/ProfileSettings/ProfileSettings";
import { Helmet } from "react-helmet";

const userFormInterface = {
  email: "",
  fullName: "",
  phoneNumber: "",
  dialCode: "",
  username: "",
};

const messageState = {
  enabled: false,
  message: "",
};

const randomColors = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const UserProfile = () => {
  let [currentView, setCurrentView] = useState("user_settings");
  let [loading, setLoading] = useState(true);
  let [userData, setUserData] = useState();
  let [userForm, setUserForm] = useState(userFormInterface);
  let [showMessage, setShowMessage] = useState(messageState);
  let [ppColor] = useState(randomColors());
  let [currentMobileView, setCurrentMobileView] = useState("menu");

  useEffect(() => {
    API.get("/dashboard/user/profile/")
      .finally(() => {
        setLoading(false);
      })
      .then((response) => {
        setUserData(response.data.results.user);
        setUserForm({
          email: response.data.results.user.email
            ? response.data.results.user.email
            : "",
          username: response.data.results.user.username
            ? response.data.results.user.username
            : "",
          fullName: response.data.results.user.full_name
            ? response.data.results.user.full_name
            : "",
          phoneNumber: response.data.results.user.phone_number
            ? response.data.results.user.phone_number
            : "",
          dialCode: response.data.results.user.nationalities.phone_code
            ? response.data.results.user.nationalities.phone_code
            : "",
        });
      });
  }, []);

  const userUpdated = (user, fromForm = false) => {
    setUserData(user);
    setUserForm({
      email: user.email ? user.email : "",
      fullName: user.full_name ? user.full_name : "",
      phoneNumber: user.phone_number ? user.phone_number : "",
      username: user.username ? user.username : "",
      dialCode: user.nationalities.phone_code
        ? user.nationalities.phone_code
        : "",
    });
    if (fromForm) {
      setShowMessage({
        enabled: true,
        message: "Profile has been Updated",
      });
    }
  };

  const allowShowMessage = (message = messageState) => {
    setShowMessage({ ...message });
  };

  const onClose = () => {
    setShowMessage({ ...messageState });
  };

  const changeMobileView = (state) => {
    setCurrentMobileView(state);
  };

  return (
    <>
      <Helmet>
        <title>iDriva - User Profile</title>
      </Helmet>
      {!loading ? (
        <>
          <div className={classes["container"]}>
            <div className={classes["menu"]}>
              {/* <img
            className={classes["menu-profile-picture"]}
            src={profilePicture}
            alt="profilePicture"
          /> */}
              {userData.profile_picture_image ? (
                <img
                  className={classes["menu-profile-picture"]}
                  src={`data:image/jpeg;base64,${userData.profile_picture_image}`}
                  alt="profilePicture"
                />
              ) : (
                <Avatar
                  color={ppColor}
                  name={`${
                    userData.full_name
                      ? userData.full_name
                      : userData.phone_number
                  }`}
                  round={true}
                />
              )}
              <p className={classes["menu-user-name"] + " " + classes["mt-2"]}>
                {userData.full_name}
              </p>
              <p className={classes["menu-username"] + " " + classes["mt-1"]}>
                {userData.username && `@${userData.username}`}
              </p>
              <div className={classes["menu-container"]}>
                <div
                  className={
                    classes["menu-item"] +
                    " " +
                    (currentView === "user_settings" && classes["active-menu"])
                  }
                  onClick={() => setCurrentView("user_settings")}
                >
                  <Icon.Profile />
                  <span className={classes["menu-item-label"]}>
                    User Settings
                  </span>
                </div>
                <div className={classes["menu-item"]}>
                  <Icon.Information />
                  <span className={classes["menu-item-label"]}>Violation</span>
                </div>
                <div className={classes["menu-item"]}>
                  <Icon.MedalStar />
                  <span className={classes["menu-item-label"]}>Badges</span>
                </div>
                <div
                  className={
                    classes["menu-item"] +
                    " " +
                    (currentView === "notification" && classes["active-menu"])
                  }
                  onClick={() => setCurrentView("notification")}
                >
                  <Icon.Notification />
                  <span className={classes["menu-item-label"]}>
                    Notifications
                  </span>
                </div>
                <div
                  className={
                    classes["menu-item"] +
                    " " +
                    (currentView === "security" && classes["active-menu"])
                  }
                  onClick={() => setCurrentView("security")}
                >
                  <Icon.SecuritySafe />
                  <span className={classes["menu-item-label"]}>Security</span>
                </div>
              </div>
            </div>
            <div className={classes["details"]}>
              {
                {
                  user_settings: (
                    <UserProfileInformation
                      formData={userForm}
                      profilePicture={userData.profile_picture_image}
                      ppColor={ppColor}
                      showMessage={allowShowMessage}
                      updatedUser={userUpdated}
                    />
                  ),
                  security: <Security />,
                  notification: <Notification />,
                }[currentView]
              }
              {/* <UserProfileInformation /> */}
              {/* <Security /> */}
              {/* <Notification /> */}
            </div>
          </div>
          <div className={classes["mobile-view"]}>
            {
              {
                menu: (
                  <UserProfileMobile
                    userData={userData}
                    changeView={changeMobileView}
                    ppColor={ppColor}
                  />
                ),
                profile_settings: (
                  <ProfileSettings
                    formData={userForm}
                    userData={userData}
                    ppColor={ppColor}
                    showMessage={allowShowMessage}
                    updatedUser={userUpdated}
                    changeView={changeMobileView}
                  />
                ),
              }[currentMobileView]
            }
          </div>
        </>
      ) : (
        <div className={classes["preloader"]}>
          <div className="sk-circle">
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
            <div className="sk-circle-dot"></div>
          </div>
        </div>
      )}
      {showMessage.enabled && (
        <AcceptTrip message={showMessage.message} onClose={onClose} />
      )}
    </>
  );
};

export default UserProfile;

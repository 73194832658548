import React, { useState, Fragment } from "react";
import classes from "./Assigned.module.css";
import TripImage from "../../assets/Group 6422.svg";
import * as Icon from "iconsax-react";
import AcceptTrip from "./AcceptTrip/AcceptTrip";
import DeclineTrip from "./DeclineTrip/DeclineTrip";
import api from "../../../services/api";

/**
 * Handle page for all assigned trips
 * 
 * @param {object} props
 * @param {object} props.onAssigned
 * @param {() => void} props.onTripHandler 
 * @param {() => void} props.refreshTripScreen 
 * @returns 
 */
const Assigned = (props) => {
  const [showAccept, setAcceptTrip] = useState(false);
  const [showDecline, setDeclineTrip] = useState(false);
  const [showTripDetails, setTripDetails] = useState();

  const showAcceptTrip = () => {
    let form = new FormData();
    form.append("state", "true");
    api.post(`/dashboard/trips/driver/assigned/state/${props.onAssigned.id}`, form)
    .then(() => {
      setAcceptTrip(true);
    })
    .catch((err) => {
      console.error(err);
    })
  };

  const hideAcceptTrip = () => {
    setAcceptTrip(false);
    props.refreshTripScreen();
  };

  const showDeclineTrip = () => {
    setDeclineTrip(true);
  };

  const hideDeclineTrip = () => {
    let form = new FormData();
    form.append("state", "false");
    api.post(`/dashboard/trips/driver/assigned/state/${props.onAssigned.id}`, form)
    .then(() => {
      setDeclineTrip(false);
      props.refreshTripScreen();
    })
    .catch((err) => {
      console.error(err);
    })
  };
  
  const dissmissModal = () => {
    setDeclineTrip(false);
  };

  const showTripDetail = () => {
    setAcceptTrip(false);
    props.onTripHandler(props.onAssigned);
  };

  // const showTrip = () => {
  //     setTripDetails(true)
  // };

  return (
    <Fragment>
      {/* <div className={classes["button-assigned"]}>
        <button>Assigned</button>
      </div> */}
      <div className={classes.container}>
        <div className={classes.background}>
          <div className={classes["assigned-header"]}>
            <h6>Assigned</h6>
          </div>
          <div className={classes["trip-info-contain"]}>
            <div className={classes["trip-id"]}>
              <h6>Trip ID - {props.onAssigned.trip_code}</h6>
            </div>
            <Icon.LocationDiscover className={classes["circle-4"]} />
          </div>

          <div>
            <div className={classes.circle}></div>
            <div className={classes["address-1"]}>
              <p className={classes.first}>{props.onAssigned.start_location}</p>
            </div>
          </div>
          <div className={classes["border-line"]}></div>
          <div className={classes["circle-2"]}></div>
          <div className={classes["address-2"]}>
            <p className={classes.first}>
              {props.onAssigned.destination_location}
            </p>
          </div>
          <div className={classes["trip-image"]}>
            <img src={TripImage} alt="SVG of car"></img>
          </div>
          <div className={classes["cab-container"]}>
            <button
              className={classes["accept-button"]}
              onClick={showAcceptTrip}
            >
              Accept Trip
            </button>
            <button
              className={classes["decline-button"]}
              onClick={showDeclineTrip}
            >
              Decline Trip
            </button>
          </div>
        </div>
      </div>
      {showAccept && (
        <AcceptTrip onClose={hideAcceptTrip} onView={showTripDetail} />
      )}
      {showDecline && <DeclineTrip onDissmiss={dissmissModal} onDecline={hideDeclineTrip} />}
      {/* {showTripDetails && <TripDetails/>} */}
    </Fragment>
  );
};

export default Assigned;

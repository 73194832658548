import React from "react";
import * as Icon from "iconsax-react";
import classes from "./Header.module.css";
import { NavLink, Outlet } from "react-router-dom";

const Header = (props) => {
  return (
    <>
      <header>
        <nav className={classes["menu-layout"]}>
          <div className={classes["icon-nav"]}>
            <ul>
              <li>
                <NavLink
                  end={`true`}
                  className={(navData) =>
                    navData.isActive ? classes["focused"] : ""
                  }
                  to="/dashboard"
                >
                  <Icon.Home2 />
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  end={`${true}`}
                  to="/dashboard/trips"
                  className={(navData) =>
                    navData.isActive ? classes["focused"] : ""
                  }
                >
                  <Icon.DirectUp />
                  Trips
                </NavLink>
              </li>
              <li>
                <NavLink
                  end={`${true}`}
                  to="/dashboard/earnings"
                  className={(navData) =>
                    navData.isActive ? classes["focused"] : ""
                  }
                >
                  <Icon.Wallet1 />
                  Earnings
                </NavLink>
              </li>
              <li>
                <NavLink
                  end={`${true}`}
                  to="/dashboard/user-profile"
                  className={(navData) =>
                    navData.isActive ? classes["focused"] : ""
                  }
                >
                  <Icon.Profile />
                  Profile
                </NavLink>
              </li>
              <li className={classes.iconNon}>
                <NavLink
                  end={`${true}`}
                  to="/dashboard/message"
                  className={(navData) =>
                    navData.isActive ? classes["focused"] : ""
                  }
                >
                  <Icon.Messages2 />
                  Messages
                </NavLink>
              </li>
              <li className={classes.iconNon}>
                <NavLink
                  end={`${true}`}
                  to="/dashboard/c-support"
                  className={(navData) =>
                    navData.isActive ? classes["focused"] : ""
                  }
                >
                  <Icon.MessageQuestion />
                  C/Support
                </NavLink>
              </li>
              <li className={classes.iconNon}>
                <NavLink className={classes.logout} to="/logout">
                  <Icon.LogoutCurve />
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default Header;

import axios from "axios";

// https://api.idriva.dev.ccol.xyz/api/v1/

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: window.localStorage.getItem("token"),
  },
});

import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import routes from "./services/routes";
import { Helmet } from "react-helmet";

function App() {
  const isLoggedIn = window.localStorage.getItem("token");
  // const { isLoggedInTest } = useSelector((state) => console.log(state));

  const routing = useRoutes(routes(isLoggedIn));

  return <>{routing}</>;

  //   return (
  //       <Router>
  //           <Fragment>
  //               <Routes>
  //                   <Route exact path="/" element={<AuthHome/>}></Route>
  //                   <Route exact path="/auth">
  //                       <Route exact path="/auth/register" element={<Register/>}></Route>
  //                       <Route exact path="/auth/login" element={<Login/>}></Route>
  //                   </Route>
  //                   <Route exact path="/dashboard">
  //                       <Route exact path="/dashboard" element={<Home/>}></Route>
  //                       <Route exact path="/dashboard/trips" element={<Trip/>}></Route>
  //                       <Route exact path="/dashboard/earnings" element={<Earnings/>}></Route>
  //                   </Route>
  //               </Routes>
  //           </Fragment>
  //       </Router>
  //   );
}
const meta = {
  title: "iDriva",
  description:
    "iDriva is a platform for drivers to manage their trips and earnings",
  canonical: "http://example.com/path/to/page",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "react,meta,document,html,tags",
    },
  },
};

const AppWrapper = () => {
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>iDriva</title>
        <meta
          name="description"
          content="iDriva is a platform for drivers to manage their trips and earnings"
        />
      </Helmet>
      <App />
    </Router>
  );
};

export default AppWrapper;

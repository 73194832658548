import React from "react";
import classes from "./LandingBackground.module.css";

const LandingBackground = (props) => {
    return (
        <div className={classes.container}>
            <div className={classes.eclipse1} />
            <div className={classes.location} />
            <div className={classes.eclipse2} />
        </div>
    )
}
export default LandingBackground;


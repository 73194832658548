import React, {useState} from "react";
import classes from "./DeclineTrip.module.css";
import * as Icon from "iconsax-react";
import DeclineTripModified from "../DeclineTripModified/DeclineTripModified";

/**
 * 
 * @param {object} props 
 * @param {() => void} props.onDissmiss
 * @param {() => void} props.onDecline
 * @returns 
 */
const DeclineTrip = (props) => {
  const [isDeclined, setIsDeclined] = useState(false)

  const declineHandler = () => {
    setIsDeclined(true);
    setTimeout(() => {
      props.onDecline();
    }, 1500);
  }

  return (
    <main>
      <div className={classes.background} onClick={props.onDissmiss}></div>
      <section className={classes["background-container"]}>
        <div>
          <Icon.CloseCircle className={classes["close-icon"]} onClick={props.onDissmiss}/>
          <p className={classes.close} onClick={props.onDecline}>Close</p>
        </div>
        <div>
          <p className={classes["trip-confirmation"]}>
            Are you sure you want to delete?
          </p>
        </div>
        {isDeclined && <DeclineTripModified/>}
        <div>
          <button className={classes['button-yes']} onClick={declineHandler}>
            Yes, Deline Trip
          </button>
          <button className={classes['button-no']} onClick={props.onDissmiss}>
            No, Go Back
          </button>
        </div>
      </section>
    </main>
  );
};

export default DeclineTrip;

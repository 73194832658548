import React, { useState, Fragment } from "react";
import classes from "./AcceptTrip.module.css";
import AcceptTripImage from "../../../assets/Successful.svg";
import * as Icon from "iconsax-react";
import TripDetails from "../../Ongoing/TripDetails/TripDetails";

const AcceptTrip = (props) => {
  const [showTripDetails, setTripDetails] = useState(false);

  const showTrip = () => {
    setTripDetails(true);
  };

  // const hideTrip = () => {
  //     setTripDetails(false)
  // };

  return (
    <Fragment>
      <main>
        <div className={classes.background} onClick={props.onClose}></div>
        <section className={classes["background-container"]}>
          <div>
            <Icon.CloseCircle
              className={classes["close-icon"]}
              onClick={props.onClose}
            />
            <p className={classes.close} onClick={props.onClose}>
              Close
            </p>
          </div>
          <div>
            <img
              src={AcceptTripImage}
              alt="SVG of Successful"
              className={classes.successful}
            ></img>
            <p className={classes["trip-confirmation"]}>
              {props.message ? props.message : "Yay, Trip has been accepted"}
            </p>
          </div>
          <div>
            {!props.message && (
              <button
                className={classes["button-successful"]}
                onClick={props.onView}
              >
                View Trip Details
              </button>
            )}
          </div>
        </section>
      </main>
      {showTripDetails && <TripDetails />}
    </Fragment>
  );
};

export default AcceptTrip;

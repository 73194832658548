import React, { useEffect, useState } from "react";
import classes from "./TripDetails.module.css";
import api from "../../../../services/api";

/**
 * 
 * @param {object} props
 * @param {object} props.onTripDetails
 * @returns 
 */
const TripDetails = (props) => {
  let [tripDetailsHandler, setTripDetailsHandler] = useState();

  useEffect(() => {
    api
      .get(`dashboard/trips/driver/full/details/${props.onTripDetails.id}`)
      .then((rest) => {
        console.log(rest);
        setTripDetailsHandler(rest.data.results.trip);
      });
  }, [props.onTripDetails.id]);

  return (
    <>
      {" "}
      {tripDetailsHandler && (
        <div className={classes.background}>
          <div className={classes["left-side"]}></div>
          <div className={classes["right-side"]}>
            <div>
              <h5 className={classes["trip-header"]}>Trip Details</h5>
              <div className={classes["trip-header-line"]}></div>
            </div>
            <div>
              <h6 className={classes.tripID}>Trip ID</h6>
              <p className={classes["tripID-value"]}>
                #{tripDetailsHandler.trip_code}
              </p>
              <h6 className={classes.vehicleNo}>Vehicle No</h6>
              <p className={classes["vehicleNo-value"]}>
                #{tripDetailsHandler.all_trip_info.vehicle_no}
              </p>
              <div className={classes["trip-line1"]}></div>
            </div>
            <div>
              <h6 className={classes.items}>Items</h6>
              <p className={classes["items-value"]}>
                {tripDetailsHandler.all_trip_info.item_value
                  ? tripDetailsHandler.all_trip_info.item_value
                  : "No items"}
              </p>
              <h6 className={classes.distance}>Distance</h6>
              <p className={classes["distance-value"]}>
                {tripDetailsHandler.distance}
              </p>
              <div className={classes["trip-line2"]}></div>
            </div>
            <div>
              <h6 className={classes["start-location"]}>Start Location</h6>
              <p className={classes["start-location-value"]}>
                {tripDetailsHandler.start_location}
              </p>
              <h6 className={classes["end-location"]}>End Location</h6>
              <p className={classes["end-location-value"]}>
                {tripDetailsHandler.destination_location}
              </p>
              <div className={classes["trip-line3"]}></div>
            </div>
            <div>
              <h6 className={classes["trip-status"]}>Trip Status</h6>
              <p className={classes["trip-status-value"]}>
                {tripDetailsHandler.status}
              </p>
              <h6 className={classes["payment-status"]}>Payment Status</h6>
              <p className={classes["payment-status-value"]}>
                {tripDetailsHandler.all_trip_info.payment_status
                  ? tripDetailsHandler.all_trip_info.payment_status
                  : "No Status"}
              </p>
              <div className={classes["trip-line4"]}></div>
            </div>
            <div>
              <button className={classes["journey-button"]}>
                View Journey Plan
              </button>
            </div>
            <div>
              <button className={classes["fuel-button"]}>
                View Fuel Docket
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TripDetails;

import React, { useEffect, useState } from "react";
import classes from "./forget-password.module.css";
import { Helmet } from "react-helmet";
import LandingBackground from "../../../UI/Landing/LandingBackground";
import ForgetPasswordForm from './ForgetPasswordForm/ForgetPasswordForm';
import VerifyPhoneNumber from "../verify-phone-number/VerifyPhoneNumber";
import AuthConfirmations from "../../../UI/AuthConfirmations/AuthConfirmations";
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';

const ForgetPassword = (props) => {
    let [currentview, setCurrentview] = useState('form');
    let [sendErrorMessage, setSendErrorMessage] = useState('');
    let [mobileNumber, setMobileNumber] = useState('');
    let [passwordToken, setPasswordToken] = useState('');
    let [timer, setTimer] = useState(5);
    let [intervalID, setIntervalID] = useState();

    useEffect(() => {
        return () => {
          clearInterval(intervalID);
        };
      }, []);

    function correctMobileNumber(number, token) {
        setMobileNumber(number);
        setPasswordToken(token);
        setCurrentview('otp')
    }

    function verifiedOwnerNumber() {
        setCurrentview('changePassword');
    }

    function successfullyChangedPassword() {
        setCurrentview('success');
        let interval = setInterval(() => {
            setTimer((timer = timer - 1));
          }, 1000);
          setIntervalID(interval);
          if (timer <= 0) {
            clearInterval(interval);
          }
          setTimeout(() => {
            window.location.href = "/auth/login";
          }, 5000);
    }

    return (
        <>
        <Helmet>
            <title>iDriva - Forget Password</title>
        </Helmet>
        <div className={classes.body}>
            <LandingBackground />
            {
                {
                    form: <ForgetPasswordForm successful={correctMobileNumber} errorMessage={sendErrorMessage} />,
                    otp: <VerifyPhoneNumber returnto="/auth/forget-password" mobileNumber={mobileNumber} successful={verifiedOwnerNumber} errorMessage={sendErrorMessage} />,
                    changePassword: <ChangePasswordForm successful={successfullyChangedPassword} mobileNumber={mobileNumber} token={passwordToken} />,
                    success: <AuthConfirmations
                    heading={`Password has been reset`}
                    message={`You will be automatically redirected to login in ${timer}`}
                />
                }[currentview]
            }
        </div>
        </>
    );
};

export default ForgetPassword;

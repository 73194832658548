import React, {useState} from "react";
import classes from "./DeclineTripModified.module.css";
import * as Icon from "iconsax-react";

const DeclineTripModified = (props) => {
  const [declineButton, setDeclineButton] = useState(false);

  const closeDeclineButton = () => {
    setDeclineButton(true);
  }

  return (
    <main>
      {/* <div className={classes.background} onClick={props.onDecline}></div> */}
      <section className={classes["background-container"]}>
        {/* <div>
          <Icon.CloseCircle className={classes["close-icon"]} onHold={props.onClick}/>
          <p className={classes.close} onClick={props.onDecline}>Close</p>
        </div> */}
        <div>
          <p className={classes["trip-confirmation"]}>
            Order has been declined!
          </p>
        </div>
      </section>
    </main>
  );
};

export default DeclineTripModified;

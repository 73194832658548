import React from "react";
import classes from './Completed.module.css';
import LocationImage from '../../assets/Location pin.svg';
import * as Icon from "iconsax-react";

const Completed = (props) => {
  return (
    <>
      <main className={classes.background}>
        <div className={classes["assigned-header"]}>
          <h6>Completed</h6>
        </div>
        <div className={classes["trip-info-contain"]}>
            <div className={classes["trip-id"]}>
              <h6>Trip ID - {props.onCompleted.trip_code}</h6>
            </div>
            <Icon.LocationDiscover className={classes["circle-4"]} />
          </div>
        <div>
          <div className={classes.circle}></div>
          <div className={classes["address-1"]}>
            <p>{props.onCompleted.start_location}</p>
          </div>
        </div>
        <div className={classes["border-line"]}></div>
        <div className={classes["circle-2"]}></div>
        <div className={classes["address-2"]}>
          <p>{props.onCompleted.destination_location}</p>
        </div>
      </main>
      <div className={classes['trip-image']}></div>
        {/* <div>
          <img src={LocationImage} alt="SVG of location" ></img>
        </div>
        <div>
        <p className={classes.LocationImage}>Select a trip to view ongoing order</p>
        </div> */}
    </>
  );
};

export default Completed;

import React, { Fragment, useEffect, useState } from "react";
import classes from "./Register.module.css";
import LandingBackground from "../../../UI/Landing/LandingBackground";
import RegisterForm from "./RegisterForm/RegisterForm";
import AuthConfirmations from "../../../UI/AuthConfirmations/AuthConfirmations";
import { useNavigate } from "react-router-dom";
import { clear } from "@testing-library/user-event/dist/clear";
import { Helmet } from "react-helmet";

const Register = (props) => {
  const [success, setSuccess] = useState(false);
  let [timer, setTimer] = useState(5);
  let [intervalID, setIntervalID] = useState();

  useEffect(() => {
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  function isSuccess() {
    setSuccess(true);
    let interval = setInterval(() => {
      setTimer((timer = timer - 1));
    }, 1000);
    setIntervalID(interval);
    if (timer <= 0) {
      clearInterval(interval);
    }
    setTimeout(() => {
      window.location.href = "/auth/login";
    }, 5000);
  }

  return (
    <>
      <Helmet>
        <title>iDriva - Register</title>
      </Helmet>
      <div className={classes.body}>
        <LandingBackground />
        {success ? (
          <AuthConfirmations
            heading={"Your Account has been created"}
            message={`You will be redirected to login in ${timer}...`}
          />
        ) : (
          <RegisterForm successful={isSuccess} />
        )}
      </div>
    </>
  );
};

export default Register;
